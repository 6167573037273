import { connect } from 'react-redux';
import { cartAction, userInfoAction, loaderAction, modalAction } from '../../../actions';
import { getFormattedDate, BreadCrumb, JQDatePicker, today } from './utilities';
import { Link } from 'react-router-dom';
// import LabTests from './labTests';
import { ConnectedLabtestCartCard, ConnectedPharmacyCartCard } from './cards';
import { useEffect, useState } from 'react';


const CartPage = ({ cart, modalAction, userInfo, modals }) => {

  const breadCrumbData = {
    links: [{name: 'Home', link: '/'}, {name: 'Cart', link: '/cartPage'}],
    activeLink: '/cartPage'
  }

  const [testDate, setTestDate] = useState(today);

  useEffect(() => {
    if (modals.LABTEST_BOOK_MODAL.data) setTestDate(modals.LABTEST_BOOK_MODAL.data)
  }, [modals.LABTEST_BOOK_MODAL.data])

  const pharmacy = Object.values(cart.pharmacy);                                                               
  const pharmacyCartLength = pharmacy.length;
  let pharmacyItemsValue = pharmacy.map(i => i.SRate * i.Qty);
  let pharmacyCartTotal = pharmacyCartLength !== 0 ? pharmacyItemsValue.reduce((total, item) => total+item).toFixed(2) : '00';

  const labTests = Object.values(cart.labTests);                                                               
  const labTestsCartLength = labTests.length;
  let labTestsItemsValue = labTests.map(i => i.SRate * i.Qty);
  let labTestsCartTotal = labTestsCartLength !== 0 ? labTestsItemsValue.reduce((total, item) => total+item).toFixed(2) : '00'; 
  
  const handleLabTestCheckout = () => {
    let companyIds = labTests.filter(i => i.CompanyId !== userInfo.selectedCompany.CompanyId);
    if (companyIds.length) return alert('Your cart has Lab tests from multiple clinics. Please remove the highlighted items.');
    console.log(companyIds);
    
    modalAction('LABTEST_BOOK_MODAL', true, testDate);
  }

  return (
    <div id="cartPage">
      <BreadCrumb data={breadCrumbData}/>
      {(!pharmacyCartLength && !labTestsCartLength) ? <EmptyCart /> : ''}
      <div className="cartPageSection container-fluid content px-3 px-lg-5">    
        {pharmacyCartLength ? <div className="row gy-3">
          <div className="col col-12 col-lg-8">
            <div className="card border-0 w-100 h-100" style={{minHeight: '17.7rem'}}>
              <h3 className="card-header border-info bg-transparent">PHARMACY <span style={{fontSize: '0.7rem'}}>({pharmacyCartLength} Products)</span></h3>             
              <div className="card-body">
                <div className="cart-list-wrapper">
                  {pharmacy.map(i => <ConnectedPharmacyCartCard item={i} key={i.ItemId}/>)}
                </div>
              </div>
              <div className="card-footer border-info bg-transparent">Date: {getFormattedDate()}</div>
            </div>
          </div>
          <div className="col col-12 col-lg-4">
            <div className="card border-0 w-100 order-summary h-100" style={{minHeight: '17.7rem'}}>
              <h3 className="card-header border-info bg-transparent">Order Summary</h3>
              <div className="card-body">
                <div className='product-list'>
                  <ul>
                    {pharmacy.map(i => <li key={i.ItemId}><div>{i.Description}<i className='bx bx-x'></i>{i.Qty}</div> ₹ {(i.Qty*i.SRate).toFixed(2)}</li>)}
                  </ul>
                </div>
                <div className="checkout-details">
                  <div>
                    <h4 className="card-title">Cart Subtotal</h4>
                    <h5 className="card-text">₹ {pharmacyCartTotal}</h5>
                  </div>
                </div>
                <div className="btn-box mt-3" style={{fontSize: '1.7em'}}>
                    <Link to={'/wishlist'} className="btn btn-main add-wishlist-btn">WISHLIST</Link>
                    <Link to={'/checkout'} className="btn btn-main">CHECKOUT</Link>
                </div>
              </div>
              <div className="card-footer border-info bg-transparent">Created DD-MM-YYYY</div>
            </div>
          </div>
        </div> : ''}

        {labTestsCartLength ? <div className="row gy-3 mt-3">
          <div className="col col-12 col-lg-8">
            <div className="card border-0 w-100 h-100" style={{minHeight: '17.7rem'}}>
              <h3 className="card-header border-info bg-transparent">LAB TESTS <span style={{fontSize: '0.7rem'}}>({labTestsCartLength} Tests)</span></h3>             
              <div className="card-body">
                <div className="cart-list-wrapper">
                  <div className="checkout-details">
                    <div className='pt-0' style={{marginBottom: '1.4em'}}>
                      <h4 className="card-title" style={{fontSize: '1.1em'}}>Booking Date</h4>
                      <JQDatePicker id={'test_date'} isRequired={true} handler={setTestDate} curValue={testDate} name={'test_date'} customClass={'form-control'} required style={{maxWidth: 'fit-content'}} />
                    </div>
                  </div>
                  {labTests.map(i => <ConnectedLabtestCartCard item={i} key={i.ItemId}/>)}
                </div>
              </div>
              <div className="card-footer border-info bg-transparent">Date: {getFormattedDate()}</div>
            </div>
          </div>
          <div className="col col-12 col-lg-4">
            <div className="card border-0 w-100 order-summary h-100" style={{minHeight: '17.7rem'}}>
              <h3 className="card-header border-info bg-transparent">Order Summary</h3>
              <div className="card-body">
                <div className='product-list'>
                  <ul>
                    {labTests.map(i => <li key={i.ItemId}><div>{i.Description}<i className='bx bx-x'></i>{i.Qty}</div> ₹ {(i.Qty*i.SRate).toFixed(2)}</li>)}
                  </ul>
                </div>
                <div className="checkout-details">
                  <div>
                    <h4 className="card-title">Cart Subtotal</h4>
                    <h5 className="card-text">₹ {labTestsCartTotal}</h5>
                  </div>
                </div>
                <div className="btn-box mt-3" style={{fontSize: '1.7em'}}>
                    <Link to={'/wishlist'} className="btn btn-main add-wishlist-btn">WISHLIST</Link>
                    <Link to={'#'} className="btn btn-main" onClick={handleLabTestCheckout}>CHECKOUT</Link>
                </div>
              </div>
              <div className="card-footer border-info bg-transparent">Created DD-MM-YYYY</div>
            </div>
          </div>
        </div> : ''}
      </div>
      <div className="section-header text-center">
          <h3 style={{"borderBottom": "2px solid gray", "textTransform": "uppercase", "display": "inline", "letterSpacing": "3px"}}>Related Products</h3>
      </div>
      {/* <LabTests mode='component'/> */}
    </div>
  )
}

const mapStateToPropsTwo = (state) => {
  return { cart: state.cart, compCode: state.compCode, userInfo: state.userInfo, isLoggedIn: state.isLoggedIn, userInfo: state.userInfo, modals: state.modals };
}

export default connect(mapStateToPropsTwo, {cartAction, userInfoAction, loaderAction, modalAction})(CartPage);


const EmptyCart = () => (
    <div className="container-fluid content emptyCart">
    <div className="row mx-md-4">
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
          <h5 className="mb-0">Shopping Cart</h5>
          </div>
          <div className="card-body cart">
            <div className="col-sm-12 empty-cart-cls text-center">
              <img src="/img/emptyCart.png" width="130" height="130" className="img-fluid mb-4 mr-3" alt="empty_cart" style={{transform: 'translateX(-11px)'}}/>
              <h3><strong>Your Cart is Empty</strong></h3>
              <h4>Add something to make me happy :)</h4>
              {/* <Link to="/labTests" className="btn btn-primary cart-btn-transform m-3 py-2 px-3" data-abc="true">Continue Shopping</Link> */}
              <div className='d-flex gap-3 justify-content-center'>
                <Link to={'/wishlist'} className="btn btn-primary mt-3 py-2 checkout-btn" style={{flex: 1, maxWidth: '11rem'}}>WISHLIST</Link>
                <Link to={'/checkout'} className="btn btn-primary mt-3 py-2 checkout-btn" style={{flex: 1, maxWidth: '11rem'}}>CHECKOUT</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)